export class ItemAvgCalculationModel {
    ItemId?: number;
    ItemUid: string;
    ItemName: string;
    UnitId?: number;
    UnitName: string;
    UsesTotalQty?: number;
    CurrentStock?: number;
    PerDay?: number;
    TentativeDayStock?: number;
    ItemusesDays?: number;
    ItemstockGoingoutDays?: number;
}

export class ItemTentative{
    SevenDaysList:ItemAvgCalculationModel[];
    FifteenDaysList:ItemAvgCalculationModel[];
    ThirtyDaysList:ItemAvgCalculationModel[];
}
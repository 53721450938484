import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  AnalyzerModel,
  DashboardQeryModel,
  DashboardViewModel,
  GeneralResModel,
  ItemTentative
} from "src/app/_models";
import { ChartDataModel } from "src/app/_models/dashboardCharts/ChartDataModel";
import { ChartPerameter } from "src/app/_models/dashboardCharts/ChartPerameter";
import { ApiService } from "../api/api.service";
import { Chart } from "./chart";

@Injectable({
  providedIn: "root",
})
export class DashboardChartService {
  private apiPATH = "DashboardCharts/";

  constructor(private router: Router, private api: ApiService) {}

  //  Date: 2023-01-09
  getDashboardData(request: DashboardQeryModel) {
    return this.api.post<GeneralResModel<DashboardViewModel>>(
      `${this.apiPATH}GetDashboardData`,
      request
    );
  }

  getDashboardAnalyzerData(request: DashboardQeryModel) {
    return this.api.post<GeneralResModel<DashboardViewModel>>(
      `${this.apiPATH}GetDashboardAnalyzerData`,
      request
    );
  }

  getDistinctAnalyzerList(request: DashboardQeryModel) {
    return this.api.post<GeneralResModel<AnalyzerModel[]>>(
      `${this.apiPATH}GetDistinctAnalyzerList`,
      request
    );
  }

  getItemTentative(request: DashboardQeryModel) {
    return this.api.post<GeneralResModel<ItemTentative>>(
      `${this.apiPATH}GetItemTentative`,
      request
    );
  }
  // Date: 2023-01-09 END

  // Previous API Calling for General Dashboard.
  // getBarChartData(fromDate:string,toDate:string,reportName:string){
  //   return this.api.get<ChartDataModel[]>(`${this.apiPATH}getBarChartData/${fromDate}/${toDate}/${reportName}`);
  // }
  getBarChartData(perameterModel: ChartPerameter, reportName: string) {
    return this.api.post<ChartDataModel[]>(
      `${this.apiPATH}getBarChartData/${reportName}`,
      perameterModel
    );
  }
  getPieChartData(perameterModel: ChartPerameter, reportName: string) {
    return this.api.post<ChartDataModel[]>(
      `${this.apiPATH}getPieChartData/${reportName}`,
      perameterModel
    );
    //return this.api.get<ChartDataModel[]>(`${this.apiPATH}getPieChartData/${fromDate}/${toDate}/${reportName}`);
  }
  getDoughnutChartData(perameterModel: ChartPerameter, reportName: string) {
    return this.api.post<ChartDataModel[]>(
      `${this.apiPATH}getDoughnutChartData/${reportName}`,
      perameterModel
    );
    //return this.api.get<ChartDataModel[]>(`${this.apiPATH}getDoughnutChartData/${fromDate}/${toDate}/${reportName}`);
  }
  getLineChartData(perameterModel: ChartPerameter, reportName: string) {
    return this.api.post<ChartDataModel[]>(
      `${this.apiPATH}getLineChartData/${reportName}`,
      perameterModel
    );
  }
  // getLineChartData(fromDate:string,toDate:string,reportName:string){
  //   return this.api.get<ChartDataModel[]>(`${this.apiPATH}getLineChartData/${fromDate}/${toDate}/${reportName}`);
  // }
  getRadTotalData(perameterModel: ChartPerameter, reportName: string) {
    return this.api.post<ChartDataModel[]>(
      `${this.apiPATH}getRadTotalData/${reportName}`,
      perameterModel
    );
    //return this.api.get<ChartDataModel[]>(`${this.apiPATH}getRadTotalData/${fromDate}/${toDate}/${reportName}`);
  }
  getchartListByUserInfo() {
    return this.api.get<Chart[]>(`${this.apiPATH}chartListByUserInfo`);
  }
  updateUserChartStatusByCharInfo(chart: any) {
    return this.api.post(
      `${this.apiPATH}updateUserChartStatusByCharInfo`,
      chart
    );
  }
  updateUserChartPositionByCharInfo(chart: any) {
    return this.api.post(
      `${this.apiPATH}updateUserChartPositionByCharInfo`,
      chart
    );
  }
  resetUserChartData() {
    return this.api.get(`${this.apiPATH}resetChart`);
  }
}

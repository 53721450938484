export class GblMenuModel {
    MenuId: number;
    MenuUid: string;
    MenuName: string;
    MenuNamespace: string;
    MenuURL: string;
    MenuIcon: string;
    Descr: string;
    Parent?: number;
    SlNo?: number;
    IsActive?: boolean;
    OrgId?: number;
    CreatedBy?: number;
    CreatedOn?: Date;
    LastModifiedBy?: number;
    LastModifiedOn?: Date;
    IsParentmenu?: boolean;
}

export class GblSubMenuModel {
    SubmenuId: number;
    SubmenuUid: string;
    MenuId?: number;
    Parent?: number;
    SubmenuType: string;
    SubmenuClassName: string;
    SubmenuNameSys: string;
    SubmenuNameUser: string;
    Descr: string;
    SlNo?: number;
    AddToHome: string;
    CanView: string;
    CanCreate: string;
    CanModify: string;
    CanRemove: string;
    IsActive: string;
    OrgId?: number;
    CreatedBy?: number;
    CreatedOn?: Date;
    LastModifiedBy?: number;
    LastModifiedOn?: Date;
    Menu: GblMenuModel;
}
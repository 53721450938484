export class ExamDtoModel {
  ExamId: number;
  ExamUid: string;
  GovtId?: number;
  ExamName: string;
  ReportHeader: string;
  ReqSample: string;
  Rate: number;
  GovtRate?: number;
  ExamType?: number;
  ServiceType: number;
  ClaimableAmt?: number;
  NonclaimableAmt?: number;
  FreeAmt?: number;
  SpecialClinic: string;
  SpecialRate?: number;
  VatApplicable: string;
  VatRate?: number;
  UnitId?: number;
  RevHeadId?: number;
  IsActive: string;
  AvgReqHrs?: number;
  MinReqHrs?: number;
  CostPrice?: number;
  ReleaseAuthLevel?: number;
  FinalizeAuthLevel?: number;
  PreparationFlag: string;
  PreparationTat?: number;
  RepeatFlag: string;
  IcdId?: number;
  AcrId?: number;
  CptId?: number;
  DefCapture?: number;
  DefImages?: number;
  IsStructuredReport: string;
  QaRequired: string;
  IsUpdated: string;
  IsDeleted: string;
  OrgId?: number;
  CreatedBy: string;
  CreatedOn?: Date;
  LastModifiedBy: string;
  LastModifiedOn?: Date;
  BpId?: number;
  StatPossible?: boolean;
  StatTatMin?: number;
  UrgentPossible?: boolean;
  UrgentTatMin?: number;
  DeferHisReconcile: string;
  IsCheckup: string;
  VipRate?: number;
  DfRad?: number;
  DfTech?: number;
  ExamBarcode: string;
  CanReqonline: string;
  AvgReqMin?: number;
  BpviewId?: number;
  ExamWeight?: number;
  IsDefault?: boolean;
  SubspecialtyId?: number;
  SubspecialtyName: string;
  DepartmentId?: number;
  DepartmentName: string;
  AnalyzeritemId?: number;
  AnalyzeritemName: string;
  ExamsampleId?: number;
  ExamsampleName: string;
  CategoryId?: number;
  CategoryName: string;
  RoomId?: number;
  RoomName: string;
  IsPanel?: boolean;
  IsCalculative?: boolean;
  DisplayOrderSl?: number;
  AnalyzerId?: number;
}

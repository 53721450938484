export class OrgTypeWiseSiteModel {
    CustomerList: SiteInfoViewModel[] = [];
    GroupList: SiteInfoViewModel[] = [];
    SubGroupList: SiteInfoViewModel[] = [];
    SiteList: SiteInfoViewModel[] = [];
}

export class SiteInfoViewModel {
    OrgId: number;
    OrgName: string;
    OrgType: string;
    GroupSiteName: string;
    OrgParentId: number | null;
    OrgGroupId: number | null;
    IsSelfsiteload: boolean | null;
}
export class ItemFastMovingModel {
  ItemId?: number;
  ItemUid: string;
  ItemName: string;
  UnitId?: number;
  UnitName: string;
  DisburseCount?: number;
  Qty?: number;
  Amount?: number;
  CurrentStock?: number;
  UsesPercentage?: number;
}

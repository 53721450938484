<div class="grid p-3">
    <div class="col-12">
        <div class="card">
            <div class="flex justify-content-between align-items-center">
                <div class="formgroup-inline">
                    <div class="field w-calendar">
                        <label for="" class="d-block mb-1">From Date</label>
                        <p-calendar name="fromDate" id="calendar" (onSelect)="onSelectFromDate($event)"
                            [readonlyInput]="true" [maxDate]="toDate" [(ngModel)]="fromDate" dateFormat="dd-mm-yy"
                            [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2018:2050"></p-calendar>
                    </div>

                    <div class="field w-calendar">
                        <label for="" class="d-block mb-1">To Date</label>
                        <p-calendar (onSelect)="onSelectFromDate($event)" name="toDate" id="calendar"
                            [readonlyInput]="true" [minDate]="fromDate" [(ngModel)]="toDate" dateFormat="dd-mm-yy"
                            [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="2018:2050"></p-calendar>
                    </div>

                    <div class="field w-calendar">
                        <label for="" class="d-block mb-1">&nbsp;</label>
                        <button class="view-btn main-study-search-btn mr-2 mb-sm-2" icon="pi pi-search" pButton
                            label="Search" (click)="getDashboardData()"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="col-12">
        <div class="grid">
            <div class="col-12 lg:col-6">
                <div class="card">
                    <div class="card-content text-center">
                        <div>
                            Daily Analyzer Distribution
                        </div>
                        <app-analyzer-profitloss *ngIf="chartData != null"
                            [chartData]="chartData"></app-analyzer-profitloss>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6">
                <div class="card">
                    <div class="card-content text-center">
                        <div>
                            Hourly Analyzer Distribution
                        </div>
                        <app-analyzer-finalized-distribution *ngIf="chartData != null"
                            [chartData]="chartData"></app-analyzer-finalized-distribution>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { RisExamDto } from "../exam/ris-exam-dto.model";

export class PatientInfoViewModel {
  RegId: number;
  Hn: string;
  OrgId?: number;
  CreatedBy?: number;
  LastModifiedBy?: number;
  OrderId?: number;
  MainExamList: RisExamDto[] = [];
}

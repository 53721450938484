export class StudyEditRequestModel {
  Hn: string;
  PacsHn: string;
  Fname: string;
  Gender: string;
  Dob: Date;
  Age: number;
  OwnerId: number;
  SpeciesId?: number;
  BreedId?: number;
  ColorId?: number;
  Neutered?: boolean;
  BreedIsMixed?: boolean;
  IsDead?: boolean;
  AccessionNo: string;
  OrderDt: Date;
  ExamId: number;
  ExamName: string;
  Status: string;
  AssignedTo?: number;
  Assignedby?: number;
  UserId?: number;
  OrgId: number;
}

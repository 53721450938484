export class MenuResModel {
    label: string;
    icon: string;
    routerLink: string[];
    items: MenuResModel[];
    jobType: string[];
}

export class MenuItem {
    label: string;
    icon: string;
    routerLink: string[];
    jobType: string[];
}
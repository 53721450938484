export class GblOrgTypeModel {
    OrgTypeId: number;
    OrgTypeCode: string;
    OrgTypeTitle: string;
    Description: string;
    IsActive?: boolean;
    CreatedBy?: number;
    CreatedOn?: Date;
    LastModifiedBy?: number;
    LastModifiedOn?: Date;
}
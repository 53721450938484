export class AppointmentWorklistSearchQuery {
  StartTime: Date;
  EndTime: Date;
  OrgId: number;
  OrgIdReferTo: number;
  OrgIdReferTos: number[];
  ModalityId: number;
  FromDate: string;
  ToDate: string;
  IsImaging: boolean;
  IsReferring: boolean;
}

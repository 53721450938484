export class PmsSpeciesModel {
  SpeciesId: number;
  SpeciesText: string;
  SpeciesName: string;
  SpeciesUID: string;
  SpeciesDesc: string;
  Measure: string;
  Weight: number;
  Increment: number;
  IsCat: boolean;
  IsDog: boolean;
  IsActive: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
}

export class PmsBreedModel {
  BreedId: number;
  BreedText: string;
  BreedDesc: string;
  SpeciesId: number;
  IsActive: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
}

export class PmsClientModel {
  ClientId: number;
  ClientUid: string;
  OwnerNameEng: string;
  OwnerNameNls: string;
  TitleNls: string;
  FnameNls: string;
  MnameNls: string;
  LnameNls: string;
  TitleEng: string;
  FnameEng: string;
  MnameEng: string;
  LnameEng: string;
  AliasName: string;
  Gender: string;
  GenderText: string;
  EmailPersonal: string;
  EmailOfficial: string;
  PhoneHome: string;
  PhoneMobile: string;
  PhoneOffice: string;
  PreferredPhone: string;
  PabxExt?: number;
  FaxNo: string;
  Address1: string;
  Address2: string;
  AliasAddress: string;
  CountyId?: number;
  CountryId: number;
  DistrictId?: number;
  ProvinceId?: number;
  ZipCode: string;
  ClientDob?: string;
  IsClinic?: boolean;
  IdentificationNumber: string;
  LineId: string;
  Balance: number;
  ImgFileName: string;
  MapAddress: string;
  MapLAT: string;
  MapLNG: string;
  IsActive: boolean;
  InActiveBy?: number;
  InActiveOn?: Date;
  InActiveReason: string;
  IsDeleted: boolean;
  DeletedReason: string;
  IsOutSource: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy: number;
  LastModifiedOn?: Date;
  OwnerNameNlsPhone: string;
}

export class PmrScheduleViewModel {
  ModalityScheduleSlotId: number;
  ScheduleId: number;
  ScheduleDT?: Date;
  PatientId: number;
  ModalityId: number;
  StartTime?: Date;
  EndTime?: Date;
  ScheduleSlotOn?: Date;
  IntervalTime: number;
  ScheduleSlotNo: number;
  IsActive: boolean;
  OrderId?: number;
  ExamId: number;
  OrgIdReferTo?: number;
  Remarks: string;
  ScheduleStatus: string;
  ScheduleStatusId?: number;
  IsConfirmed?: boolean;
  IsPrivate?: boolean;
  PrivateBy?: number;
  PrivateOn?: Date;
  PrivateReason: string;
  IsDefault?: boolean;
  ConfirmedBy: number;
  ConfirmedOn?: Date;
  ConfirmedRemarks: string;
  IsBlock?: boolean;
  BlockBy: number;
  BlockOn?: Date;
  BlockReason: string;
  IsCancel?: boolean;
  CancelBy: number;
  CancelOn?: Date;
  CancelReason: string;
  OrgId: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  DailogType: string;
  OwnerId?: number;
  OwnerName: string;
  OwnerNameNls: string;
  OwnerFnameEng: string;
  OwnerMnameEng: string;
  OwnerLnameEng: string;
  OwnerFnameNls: string;
  OwnerMnameNls: string;
  OwnerLnameNls: string;
  OwnerTitleNls: string;
  OwnerTitleEng: string;
  Addr1: string;
  OrgName: string;
  OrgRefName: string;
  PatientName: string;
  PatientOwnerName: string;
  ModalityName: string;
  ExamName: string;
  AccessionNo: string;
  ColorId?: number;
  SpeciesId?: number;
  IsNuter?: boolean;
  BreedId: number;
  Gender: string;
  Dob?: Date;
  Hn: string;
  Fname: string;
  FromDate: string;
  ToDate: string;
  PatientNameEng: string;
  ExamDt?: Date;
  NoOfImage: number;
  ExamType?: number;
  Status: string;
  StatusName: string;
  OrderDt?: Date;
  FnameEng: string;
  Lname: string;
  LnameEng: string;
  Mname: string;
  MnameEng: string;
  RegId: number;
  ExamCode: string;
  CancelHost: string;
  CancelledBy: number;
  CancelledReason: string;
  CancelledOn: Date;
  SSN: string;
  Color: string;
  FinalizedOn?: Date;
  FinalizedBy: string;
  OrgParentId?: number;
  StandardExamName: string;
  PatientType: string;
  AssignedTo?: number;
  Assignedby?: number;
  AssignedToName: string;
  IsDraft?: boolean;
  IsReset?: boolean;
  VersionNo?: number;
  ResetBy?: number;
  ResetByName: string;
  ResetOn?: Date;
  ResetTo: string;
  ResetreasonId?: number;
  SpeciesName: string;
  ColorText: string;
  BreedText: string;
  Neutered?: boolean;
  BreedIsMixed?: boolean;
  IsDead?: boolean;
  IsChecked: boolean;
  IsDisabled: boolean;
  AvgInvTime:number;
}

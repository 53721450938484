import { AnalyzerDietarytypeMapping } from "./analyzer/analyzer-dietarytype-mapping.model";
import { AnalyzerDietarytypeModel } from "./analyzer/analyzer-dietarytype.model";
import { AnalyzerModel } from "./analyzer/analyzer.model";
import { DietarytypeModel } from "./analyzer/dietarytype.model";
import { AppointmentTimeSlotModel } from "./appointment/appointment-time-slot.model";
import { AppointmentViewModel } from "./appointment/appointment-view.model";
import { AppointmentWorklistSearchQuery } from "./appointment/appointment-worklist-search-query.model";
import { FilterModalitySlotViewModel } from "./appointment/filter-modality-slot-view.model";
import { PmrScheduleViewModel } from "./appointment/pmr-schedule-view.model";
import { CustomerListModel } from "./customer-list/customer-list.model";
import { DashboardQeryModel } from "./dashboardCharts/dashboard-query.model";
import { DashboardViewModel } from "./dashboardCharts/dashboard-view.model";
import { ItemAvgCalculationModel } from "./dashboardCharts/item-avg-calculation.model";
import { ExamDtoModel } from "./exam/exam-dto.model";
import { ExamImageQualityModel } from "./exam/exam-image-quality.model";
import { ExamOrderRequestModel } from "./exam/exam-order-request.model";
import { ExamResultHistoryResModel } from "./exam/exam-result-history-res.model";
import { ExamResultNoteHistoryResModel } from "./exam/exam-result-note-history-res.model";
import { ExamResultNote } from "./exam/exam-result-note.model";
import { ExamResultSeverityModel } from "./exam/exam-result-severity.model";
import { RisExamconsumablesDto } from "./exam/ris-exam-consumables-dto.model";
import { RisExamDto } from "./exam/ris-exam-dto.model";
import { RisExamItemsDto } from "./exam/ris-exam-items-dto.model";
import { RisExamPanelDtlModel } from "./exam/ris-exam-panel-dtl.model";
import { RisExamPanelModel } from "./exam/ris-exam-panel.model";
import { RisExamTypeModel } from "./exam/ris-exam-type.model";
import { GblEnvResModel } from "./gbl-env/gbl-env-res.model";
import { GblOrgTypeModel } from "./gbl-env/gbl-org-type.model";
import { GeneralResModel } from "./general/general-res.model";
import { OrgTypeWiseSiteModel, SiteInfoViewModel } from "./group/orgtype-wise-site.model";
import { ResendToGovTypeModel } from "./integration-monitoring-center/resend-to-gov-type.model";
import { ResendToGovViewModel } from "./integration-monitoring-center/resend-to-gov-view.model";
import { SentStudyListModel } from "./integration-monitoring-center/sent-study-list.model";
import { InvGoodsIssueDtlModel } from "./inventory/inv-goods-issue-dtl.model";
import { InvPOViewModel } from "./inventory/inv-po-view.model";
import { InvQueryViewModel } from "./inventory/inv-query-view.model";
import { LedgerItemModel } from "./inventory/ledger-item.model";
import { SlideTrackerData } from "./inventory/slide-tracker-data.model";
import { InvCategoryModel } from "./lab-setup/inv-category.model";
import { InvItemImagesModel } from "./lab-setup/inv-item-images.model";
import { InvItemtypeModel } from "./lab-setup/inv-item-type.model";
import { InvItemModel } from "./lab-setup/inv-item.model";
import { InvUnitModel } from "./lab-setup/inv-unit.model";
import { InvVendorModel } from "./lab-setup/inv-vendor.model";
import { ItemFastMovingModel } from "./lab-setup/item-fast-moving.model";
import {
  ItemImgesViewModel,
  LabSetupViewModel,
} from "./lab-setup/lab-setup-view.model";
import { RisExamconsumablesModel } from "./lab-setup/ris-exam-consumables.model";
import { RisExamsample } from "./lab-setup/ris-exam-sample.model";
import { GblMenuModel } from "./Menu/gbl-menu.model";
import { MenuResModel } from "./Menu/menu-model-res";
import { AiListModel } from "./open-ai/ai-list.model";
import { OpenAiModel } from "./open-ai/open-ai.model";
import { OrderclinicalsignificanceModel } from "./order-clinical-significance/order-clinical-significance.model";
import { DocumentHistoryResModel } from "./order-document/document-history-res.model";
import { OrderDocumentDownloadResModel } from "./order-document/order-document-download-res.model";
import { OrderDocumentModel } from "./order-document/order-document-model";
import { OrderDocumentTypeResModel } from "./order-document/order-document-type-res.model";
import { OrderScoreModel } from "./order-score/order-score.model";
import { OrderStudyDeclineReqModel } from "./order-study-decline/order-study-decline-req.model";
import { GblEnvViewModel } from "./orginfo/gbl-env-view.model";
import { PmsBreedModel } from "./pms/pms-breed.model";
import { PmsClientModel } from "./pms/pms-client.model";
import { PmsPetColorModel } from "./pms/pms-pet-color.model";
import { PmsSpeciesModel } from "./pms/pms-species.model";
import { RadProfileStudyCountChartModel } from "./radiologist-profile/rad-profile-study-count-chart.model";
import { GblPostalCode } from "./radiologist-signup/GblPostalCode";
import { RatingViewModel } from "./rating/rating-view.model";
import { HisRegistrationModel } from "./registration/his-registration.model";
import { GblReportJobtypeSettingModel } from "./report/gbl-report-jobtype-setting.model";
import { GblReportParameterSettingModel } from "./report/gbl-report-parameter-setting.model";
import { GblReportParameterModel } from "./report/gbl-report-parameter.model";
import { GblReportViewModel } from "./report/gbl-report-view.model";
import { GblReportModel } from "./report/gbl-report.model";
import { ReportFilterViewModel } from "./report/report-filter-view.model";
import { ResetStudyReqModel } from "./reset-study/reset-study-req.model";
import { StudyResetReasonResModel } from "./reset-study/study-reset-reason-res.model";
import { StudyVersionResModel } from "./reset-study/study-version-res.model";
import { OrgResponseViewModel } from "./ris-order/org-response-view.model";
import { RisExamResultKeyImagesViewModel } from "./ris-order/ris-examresult-key-images-view.model";
import { RisExamResultKeyImagesModel } from "./ris-order/ris-examresult-key-images.model";
import { RisModalityScheduleSlotModel } from "./ris-order/ris-modality-schedule-slot.model";
import { RisModalityModel } from "./ris-order/ris-modality.model";
import { RisOrderDtlModel } from "./ris-order/ris-order-dtl.model";
import { RisOrderSubmitDTLAPISendLogViewModel } from "./ris-order/ris-order-submit-dtl-api-send-log-view.model";
import { RisOrderModel } from "./ris-order/ris-order.model";
import { ServiceTypeModel } from "./service-type/service-type.model";
import { ChangeOrderStatusReqModel } from "./study/change-order-status-req.model";
import { OwnerWiseStudyResultHistoryModel } from "./study/owner-wise-study-history.model";
import { PatientWiseStudyHistoryModel } from "./study/patient-wise-study-history.model";
import { StudyEditRequestModel } from "./study/study-edit-request.model";
import { StudyQueryModel } from "./study/study-query.model";
import { StudyReportingTemplateDropdownResModel } from "./study/study-reporting-template-dropdown-res.model";
import { StudyReportingTemplateModel } from "./study/study-reporting-template.model";
import { StudyTrackingModel } from "./study/study-tracking.model";
import { UpdateStudyReqModel } from "./study/update-study-req.model";
import { TwoFABackupCodeResModel } from "./two-fa/two-fa-backup-code-res.model";
import { TwoFABackupCodeModel } from "./two-fa/two-fa-backup-code.model";
import { TwoFaVerifyModel } from "./two-fa/two-fa-verify.model";
import { ClientPatientResponseModel } from "./user-models/client-patient-response.model";
import { HrEmpOrgMappingModel } from "./user-models/hr-emp-org-mapping.model";
import { HrEmpProfileModel } from "./user-models/hr-emp-profile.model";
import { HrEmpStudyChartModel } from "./user-models/hr-emp-study-chart.model";
import { HrEmpSubspecialtyModel } from "./user-models/hr-emp-subspecialty.model";
import { HrEmpModel } from "./user-models/hr-emp.model";
import { HrJobtitleModel } from "./user-models/hr-jobtitle.model";
import { PatientInfoViewModel } from "./user-models/patient-information-view.model";
import { PatientInformationModel } from "./user-models/patient-information.model";
import { UserPreservedDataModel } from "./user-models/user-preserved-data.model";

export const models: any[] = [
  MenuResModel,
  GblMenuModel,
  StudyQueryModel,
  SiteInfoViewModel,
  OrgTypeWiseSiteModel,
  GblOrgTypeModel,
  ItemAvgCalculationModel,
  TwoFaVerifyModel,
  TwoFABackupCodeModel,
  TwoFABackupCodeResModel,
  ItemFastMovingModel,
  DashboardQeryModel,
  DashboardViewModel,
  ItemImgesViewModel,
  InvItemImagesModel,
  InvGoodsIssueDtlModel,
  SlideTrackerData,
  InvQueryViewModel,
  LedgerItemModel,
  InvPOViewModel,
  RisExamconsumablesModel,
  InvUnitModel,
  InvItemtypeModel,
  InvCategoryModel,
  InvItemModel,
  InvVendorModel,
  RisExamDto,
  RisExamconsumablesDto,
  PatientInfoViewModel,
  RisExamItemsDto,
  RisExamsample,
  ExamOrderRequestModel,
  RisExamPanelDtlModel,
  RisExamPanelModel,
  PatientInformationModel,
  ClientPatientResponseModel,
  LabSetupViewModel,
  RisExamResultKeyImagesViewModel,
  RisExamResultKeyImagesModel,
  GblPostalCode,
  FilterModalitySlotViewModel,
  AppointmentTimeSlotModel,
  PmrScheduleViewModel,
  AppointmentWorklistSearchQuery,
  AppointmentViewModel,
  RisModalityScheduleSlotModel,
  GblEnvViewModel,
  StudyEditRequestModel,
  HrEmpOrgMappingModel,
  GeneralResModel,
  OrderDocumentModel,
  OrderDocumentTypeResModel,
  OrderDocumentDownloadResModel,
  CustomerListModel,
  OrderStudyDeclineReqModel,
  ExamResultNote,
  DocumentHistoryResModel,
  RatingViewModel,
  ServiceTypeModel,
  UpdateStudyReqModel,
  GblEnvResModel,
  OrderScoreModel,
  OrderclinicalsignificanceModel,
  StudyTrackingModel,
  UserPreservedDataModel,
  ExamResultSeverityModel,
  ChangeOrderStatusReqModel,
  ResetStudyReqModel,
  StudyVersionResModel,
  ExamResultHistoryResModel,
  ExamResultNoteHistoryResModel,
  StudyResetReasonResModel,
  StudyReportingTemplateModel,
  StudyReportingTemplateDropdownResModel,
  RisExamTypeModel,
  ExamImageQualityModel,
  ExamDtoModel,
  GblReportModel,
  GblReportParameterSettingModel,
  GblReportJobtypeSettingModel,
  GblReportParameterModel,
  GblReportViewModel,
  ReportFilterViewModel,
  HrEmpModel,
  HrEmpProfileModel,
  HrEmpSubspecialtyModel,
  HrJobtitleModel,
  RisOrderModel,
  RisOrderDtlModel,
  RisModalityModel,
  HisRegistrationModel,
  HrEmpStudyChartModel,
  RadProfileStudyCountChartModel,
  RisOrderSubmitDTLAPISendLogViewModel,
  OrgResponseViewModel,
  ResendToGovViewModel,
  ResendToGovTypeModel,
  SentStudyListModel,
  PmsBreedModel,
  PmsSpeciesModel,
  PmsPetColorModel,
  PmsClientModel,
  OpenAiModel,
  AiListModel,
  AnalyzerModel,
  AnalyzerDietarytypeModel,
  DietarytypeModel,
  AnalyzerDietarytypeMapping,
  PatientWiseStudyHistoryModel,
  OwnerWiseStudyResultHistoryModel,
];

export * from "./analyzer/analyzer-dietarytype-mapping.model";
export * from "./analyzer/analyzer-dietarytype.model";
export * from "./analyzer/analyzer.model";
export * from "./analyzer/dietarytype.model";
export * from "./appointment/appointment-time-slot.model";
export * from "./appointment/appointment-view.model";
export * from "./appointment/appointment-worklist-search-query.model";
export * from "./appointment/filter-modality-slot-view.model";
export * from "./appointment/pmr-schedule-view.model";
export * from "./customer-list/customer-list.model";
export * from "./dashboardCharts/dashboard-query.model";
export * from "./dashboardCharts/dashboard-view.model";
export * from "./dashboardCharts/item-avg-calculation.model";
export * from "./exam/exam-dto.model";
export * from "./exam/exam-image-quality.model";
export * from "./exam/exam-order-request.model";
export * from "./exam/exam-result-history-res.model";
export * from "./exam/exam-result-note-history-res.model";
export * from "./exam/exam-result-note.model";
export * from "./exam/exam-result-severity.model";
export * from "./exam/ris-exam-consumables-dto.model";
export * from "./exam/ris-exam-dto.model";
export * from "./exam/ris-exam-items-dto.model";
export * from "./exam/ris-exam-panel-dtl.model";
export * from "./exam/ris-exam-panel.model";
export * from "./exam/ris-exam-type.model";
export * from "./gbl-env/gbl-env-res.model";
export * from "./gbl-env/gbl-org-type.model";
export * from "./general/general-res.model";
export * from "./group/orgtype-wise-site.model";
export * from "./integration-monitoring-center/resend-to-gov-type.model";
export * from "./integration-monitoring-center/resend-to-gov-view.model";
export * from "./integration-monitoring-center/sent-study-list.model";
export * from "./inventory/inv-goods-issue-dtl.model";
export * from "./inventory/inv-po-view.model";
export * from "./inventory/inv-query-view.model";
export * from "./inventory/ledger-item.model";
export * from "./inventory/slide-tracker-data.model";
export * from "./lab-setup/inv-category.model";
export * from "./lab-setup/inv-item-images.model";
export * from "./lab-setup/inv-item-type.model";
export * from "./lab-setup/inv-item.model";
export * from "./lab-setup/inv-unit.model";
export * from "./lab-setup/inv-vendor.model";
export * from "./lab-setup/item-fast-moving.model";
export * from "./lab-setup/lab-setup-view.model";
export * from "./lab-setup/ris-exam-consumables.model";
export * from "./lab-setup/ris-exam-sample.model";
export * from "./Menu/gbl-menu.model";
export * from "./Menu/menu-model-res";
export * from "./open-ai/ai-list.model";
export * from "./open-ai/open-ai.model";
export * from "./order-clinical-significance/order-clinical-significance.model";
export * from "./order-document/document-history-res.model";
export * from "./order-document/order-document-download-res.model";
export * from "./order-document/order-document-model";
export * from "./order-document/order-document-type-res.model";
export * from "./order-score/order-score.model";
export * from "./order-study-decline/order-study-decline-req.model";
export * from "./orginfo/gbl-env-view.model";
export * from "./pms/pms-breed.model";
export * from "./pms/pms-client.model";
export * from "./pms/pms-pet-color.model";
export * from "./pms/pms-species.model";
export * from "./radiologist-profile/rad-profile-study-count-chart.model";
export * from "./radiologist-signup/GblPostalCode";
export * from "./rating/rating-view.model";
export * from "./registration/his-registration.model";
export * from "./report/gbl-report-jobtype-setting.model";
export * from "./report/gbl-report-parameter-setting.model";
export * from "./report/gbl-report-parameter.model";
export * from "./report/gbl-report-view.model";
export * from "./report/gbl-report.model";
export * from "./report/report-filter-view.model";
export * from "./reset-study/reset-study-req.model";
export * from "./reset-study/study-reset-reason-res.model";
export * from "./reset-study/study-version-res.model";
export * from "./ris-order/org-response-view.model";
export * from "./ris-order/ris-examresult-key-images-view.model";
export * from "./ris-order/ris-examresult-key-images.model";
export * from "./ris-order/ris-modality-schedule-slot.model";
export * from "./ris-order/ris-modality.model";
export * from "./ris-order/ris-order-dtl.model";
export * from "./ris-order/ris-order-submit-dtl-api-send-log-view.model";
export * from "./ris-order/ris-order.model";
export * from "./service-type/service-type.model";
export * from "./study/change-order-status-req.model";
export * from "./study/owner-wise-study-history.model";
export * from "./study/patient-wise-study-history.model";
export * from "./study/study-edit-request.model";
export * from "./study/study-query.model";
export * from "./study/study-reporting-template-dropdown-res.model";
export * from "./study/study-reporting-template.model";
export * from "./study/study-tracking.model";
export * from "./study/update-study-req.model";
export * from "./two-fa/two-fa-backup-code-res.model";
export * from "./two-fa/two-fa-backup-code.model";
export * from "./two-fa/two-fa-verify.model";
export * from "./user-models/client-patient-response.model";
export * from "./user-models/hr-emp-org-mapping.model";
export * from "./user-models/hr-emp-profile.model";
export * from "./user-models/hr-emp-study-chart.model";
export * from "./user-models/hr-emp-subspecialty.model";
export * from "./user-models/hr-emp.model";
export * from "./user-models/hr-jobtitle.model";
export * from "./user-models/patient-information-view.model";
export * from "./user-models/patient-information.model";
export * from "./user-models/user-preserved-data.model";


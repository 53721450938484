import { RisExamItemsDto } from "./ris-exam-items-dto.model";

export class RisExamconsumablesDto {
  ExamconsumablesUid: string;
  ExamId?: number;
  ExamName: string;
  OrgId?: number;
  CreatedBy?: number;
  LastModifiedBy?: number;
  ItemList: RisExamItemsDto[] = [];
}

import { Injectable } from '@angular/core';
import { GblMenuModel, GeneralResModel, MenuResModel } from 'src/app/_models';
import { GblJobtypemenuepermision } from 'src/app/_models/Menu/gbl-jobtypemenu-pemission.model';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AppMenuService {

  private apiPath = 'Menu/';

  constructor(
    private _api: ApiService
  ) { }

  
  getGblMenus() {
    return this._api.get<GeneralResModel<GblMenuModel[]>>(`${this.apiPath}GetGblMenus`);
  }

  createMenu(request: GblMenuModel) {
    return this._api.post<GeneralResModel<GblMenuModel>>(`${this.apiPath}CreateMenu`, request);
  }

  updateMenu(request: GblMenuModel) {
    return this._api.post<GeneralResModel<GblMenuModel>>(`${this.apiPath}UpdateMenu`, request);
  }
  
  deleteMenu(request: GblMenuModel) {
    return this._api.post<GeneralResModel<GblMenuModel>>(`${this.apiPath}DeleteMenu`, request);
  }

  getMenuList(empId: number) {
    return this._api.get<GeneralResModel<MenuResModel[]>>(`${this.apiPath}GetMenuList/${empId}`);
  }
  getJobtypemenuepermisions(orgId:number) {
    return this._api.get<GeneralResModel<GblJobtypemenuepermision[]>>(`${this.apiPath}GetJobtypemenuepermisions/${orgId}`);
  }
  getJobtypemenuepermisionById(permissionmenuId:number) {
    return this._api.get<GeneralResModel<GblJobtypemenuepermision>>(`${this.apiPath}GetJobtypemenuepermisionById/${permissionmenuId}`);
  }

  createJobtypemenuepermision(request: GblJobtypemenuepermision) {
    return this._api.post<GeneralResModel<GblJobtypemenuepermision>>(`${this.apiPath}CreateJobtypemenuepermision`, request);
  }

  updateJobtypemenuepermision(request: GblJobtypemenuepermision) {
    return this._api.post<GeneralResModel<GblJobtypemenuepermision>>(`${this.apiPath}UpdateJobtypemenuepermision`, request);
  }
  
  deleteJobtypemenuepermision(request: GblJobtypemenuepermision) {
    return this._api.post<GeneralResModel<GblJobtypemenuepermision>>(`${this.apiPath}DeleteJobtypemenuepermision`, request);

  }

  getActiveGblMenus() {
    return this._api.get<GeneralResModel<GblMenuModel[]>>(`${this.apiPath}GetActiveGblMenus`);
  }
}

import { RisExam } from "./ris-exam.model";

export class RisExamPanelModel {
  PanelId: number;
  PanelUid: string;
  PanelName: string;
  ExamId?: number;
  RoomId?: number;
  Tag: string;
  Rate: number;
  DisplayOrder?: number;
  IsActive?: boolean;
  IsVisible?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  Exam: RisExam;
  Panelexam: RisExam;
  DepartmentId?: number;
  DepartmentName: string;
  ExamsampleId?: number;
  ExamsampleName: string;
}

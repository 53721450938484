export class DietarytypeModel {
  DietarytypeId: number;
  DietaryType: string;
  Description: string;
  SerialNo?: number;
  IsActive?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
}

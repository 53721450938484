import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit,OnChanges{
     currentWidth: number;
     currentHeight: number;
    horizontalMenu: boolean;

    darkMode = false;

    menuColorMode = 'light';

    menuColor = 'layout-menu-light';

    themeColor = 'blue';

    layoutColor = 'blue';

    ripple = true;

    inputStyle = 'outlined';

    constructor(private primengConfig: PrimeNGConfig) {}

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.onResize();
    }
    ngOnChanges(changes: SimpleChanges): void {
       this.onResize();
    }

    onResize(){
        this.currentWidth = window.innerWidth;
        this.currentHeight = window.innerHeight;
    let compressWSize = ( this.currentWidth - Math.round((this.currentWidth*0.02)));
    let compressHSize = ( this.currentHeight - Math.round((this.currentHeight*0.1)));
        // You can add conditions or actions based on new sizes
        console.log(`Width: ${this.currentWidth}, Height: ${this.currentHeight}`);
        
        console.log(`compress Width: ${compressWSize}, compress Height: ${compressHSize}`);
    
        // Example: Different actions based on screen width
        if (this.currentWidth < 768) {
          console.log('Screen size is small (mobile view).');
          window.resizeTo(compressWSize,this.currentHeight);
          // Perform actions for small screens
        } else if (this.currentWidth >= 768 && this.currentWidth < 1024) {
          console.log('Screen size is medium (tablet view).');
          window.resizeTo(compressWSize,this.currentHeight);
          // Perform actions for medium screens
        } else {
          console.log('Screen size is large (desktop view).');
          window.resizeTo(compressWSize,this.currentHeight);
          // Perform actions for large screens
        }
    }
}

export class HisRegistrationModel {
  RegId: number;
  Hn: string;
  Title: string;
  RegDt?: Date;
  Fname: string;
  Mname: string;
  Lname: string;
  TitleEng: string;
  FnameEng: string;
  MnameEng: string;
  LnameEng: string;
  Ssn: string;
  Dob?: Date;
  Age?: number;
  Addr1: string;
  Addr2: string;
  Addr3: string;
  Addr4: string;
  Addr5: string;
  Phone1: string;
  Phone2: string;
  Phone3: string;
  Email: string;
  Gender: string;
  GenderText: string;
  MaritalStatus: string;
  OccupationId?: number;
  Nationality: string;
  PassportNo: string;
  BloodGroup: string;
  Religion: string;
  PatientType: string;
  BlockPatient: string;
  EmContactPerson: string;
  EmRelation: string;
  EmAddr: string;
  EmPhone: string;
  InsuranceType: string;
  Hl7Format: string;
  Hl7Send: string;
  LinkDown: string;
  Allergies: string;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  IsDeleted: string;
  IsUpdated: string;
  Picture: string;
  IsJohndoe: string;
  HisHn: string;
  ExtHn: string;
  PacsHn: string;
  HospitalHn: string;
  OWNRPID: string;
  OWNNAME: string;
  CountryId?: number;
  ProvinceId?: number;
  DistrictId?: number;
  SubdistrictId?: number;
  ZipCode?: number;
  PcrDate?: Date;
  OwnerId?: number;
  BreedId?: number;
  BreedIsMixed?: boolean;
  ColorId?: number;
  SpeciesId?: number;
  IsNuter?: boolean;
  IsDead?: boolean;
  BodyWeight: string;
  OwnerName: string;
  Year?: number;
  Month?: number;
  Day?: number;
  PostalCodeId?: number;
}

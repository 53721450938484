export class RisModalityScheduleSlotModel {
  ModalityScheduleSlotId: number;
  ScheduleSlotUid: string;
  ScheduleSlotName: string;
  ModalityId: number;
  ScheduleSlotNo: number;
  ScheduleSlotOn?: Date;
  IsActive: boolean;
  IsPrivate: boolean;
  PrivateBy: number;
  PrivateOn?: Date;
  PrivateReason: string;
  IsBlock?: boolean;
  BlockBy?: number;
  BlockOn?: Date;
  BlockReason: string;
  IsDefault?: boolean;
  OrgId: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  StartTime?: Date;
  EndTime?: Date;
  AvgInvTime?: number;
  time: string;
  label: string;
}

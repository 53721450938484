export class LedgerItemModel {
  Type: string;
  ItemId?: number;
  Qty?: number;
  ItemName: string;
  UnitName: string;
  UnitPrice?: number;
  Amount?: number;
  CreatedOn?: Date;
  RefNo: string;
  RefType: string;
  RefId?: number;
  TransNo: string;
  TransId?: number;
}

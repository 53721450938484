import { PatientInformationModel } from "../user-models/patient-information.model";

export class PatientWiseStudyHistoryModel extends PatientInformationModel {
    MaxNoOfExamResult?: number;
    StudyList: ExamInformation[] = [];
}

export class ExamInformation {
    ExamId: number;
    ExamUid: string;
    ExamName: string;
    CurrentResult: string;
    CurrentExamDate: string;
    ExamPreviousResultList: ExamResultInformation[] = [];
    ExamResultList: ExamResultInformation[] = [];
}

export class ExamResultInformation {
    ExamId: number;
    ExamDate: string;
    Result: string;
    Hit: string;
}

export class StudyHistoryQuery {
    Hn: string;
    OrgId?: number;
    RegId?: number;
}

export class PatientHistoryChart{
    
    ExamName: string;
    ExamDate: string;
    Result: string;
    data?:any[];
}
export class AnalyzerDietarytypeModel {
    AnalyzerdietarytypeId: number;
    AnalyzerId?: number;
    AnalyzerName: string;
    DietarytypeId?: number;
    DietaryType: string;
    SerialNo?: number;
    IsActive?: boolean;
    IsDefault?: boolean;
    OrgId?: number;
    CreatedBy?: number;
    CreatedOn?: Date;
    LastModifiedBy?: number;
    LastModifiedOn?: Date;
}
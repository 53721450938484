import { PatientInformationModel } from "../user-models/patient-information.model";

export class OwnerWiseStudyResultHistoryModel {
    OwnerTitleEng: string;
    OwnerFnameEng: string;
    OwnerMnameEng: string;
    OwnerLnameEng: string;
    OwnerGender: string;
    OwnerName: string;
    OwnerNameNls: string;
    OwnerEmail: string;
    OwnerAddress: string;
    OwnerPhoneMobile: string;
    PatientHistory: PatientStudyExamHistory[];
}

export class PatientStudyExamHistory extends PatientInformationModel {
    MaxNoOfExamResult: number | null;
    StudyList: ExamResultIno[];
}

export class ExamResultIno {
    ExamId: number;
    ExamUid: string;
    ExamName: string;
    CurrentResult: string;
    CurrentExamDate: string;
    ExamPreviousResultList: ExamResultDataInformation[];
}

export class ExamResultDataInformation {
    ExamId: number;
    ExamDate: string;
    Result: string;
    Hit: string;
}

export class StudyOwnerHistoryQuery {
    Hn: string;
    OrgId: number | null;
    OwnerId: number | null;
    RegId: number | null;
}
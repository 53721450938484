<div class="exception-body notfound">
    <div class="exception-content">
        <div class="moon">
            <img src="assets/layout/images/pages/asset-moon.svg" alt="mirage-layout" />
        </div>
        <div class="exception-panel">
            <div class="exception-panel-content">
                <span class="tag">404</span>
                <h1>Page Not Found</h1>
                <div class="seperator"></div>
                <p>Requested resource is not available right now. Please try again later.</p>
                <button pButton type="button" [routerLink]="['/']" label="Go To DashBoard"></button>
            </div>
        </div>
        <div class="desert"></div>
    </div>
</div>

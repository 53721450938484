import { Component, Input, OnInit } from '@angular/core';
import { DailyAnalyzerFinalizedDistribution, DashboardViewModel } from 'src/app/_models';

@Component({
  selector: 'app-analyzer-profitloss',
  templateUrl: './analyzer-profitloss.component.html',
  styleUrls: ['./analyzer-profitloss.component.scss']
})
export class AnalyzerProfitlossComponent implements OnInit {

  @Input() chartData: DashboardViewModel = new DashboardViewModel();
  data: DailyAnalyzerFinalizedDistribution[] = [];
  barData: any;
  barOptions: any;

  colors: string[] = [
    "#1AB587",
    "#93A2E6",
    "#31356E",
    "#90D4D6",
    "#363C57",
    "#516CCD",
    "#959BB8",
    "#A6ED9D",
    "#4D9DE0",
    "#BD4CE0",
    "#A30041",
    "#663400",
    "#A33A83",
    "#86138C",
    "#E04C4C",
    "#8C1313",
    "#F200A9",
    "#CE6A00",
    "#FF0066",
    "#CE9456",
    "#FFD400",
    "#FF2323",
    "#E07474",
    "#FF9E9E",
    "#D8BC2D",
  ];

  constructor() {}

  ngOnChanges(): void {
    // alert('ddd');
    this.data = [];
    if (this.chartData.DailyAnalyzerFinalizedDistribution) {
      this.data.push(
        ...this.chartData.DailyAnalyzerFinalizedDistribution.map((x) => ({
          AnalyzerName: x.AnalyzerName,
          DayName: x.DayName,
          DayNo: x.DayNo,
          TotalStudyCount: x.TotalStudyCount,
        }))
      );
      if (this.data) {
        this.getSlidePL();
      }
    }
  }

  ngOnInit(): void {
    this.data = [];
  }

  private getSlidePL() {
    const labels: string[] = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
    const datasets = [];

    const slidePlColors: string[] = [
      "#ff8a67",
      "#fab06b",
      "#fc6b6b",
      "#ffcc00",
      "#9933ff",
      "#ff6666",
    ];


    // {
    //   label: "Analyzer Profit & Loss",
    //   data: [],
    //   fill: false,
    //   backgroundColor: [],
    //   borderColor: [],
    //   tension: 0.4,
    // },
  
        // Create unique list of exam names for labels
        const uniqueAnalyzerName = [...new Set(this.data.map(item => item.AnalyzerName))];
  
        //  labels =hourlyLabels;
         uniqueAnalyzerName.forEach((item, index)=>{
          datasets.push( {
            label: item,
            data: [],
            fill: false,
            backgroundColor: [],
            borderColor: [],
            tension: 0.4,
          },
        );
          const colorIndex = index % slidePlColors.length;
        const dfColor = slidePlColors[colorIndex];
  
        datasets[index].backgroundColor.push(dfColor);
        datasets[index].borderColor.push(dfColor);
        const analyzerIndex = index;
        // datasets[0].data.push(item.TotalStudyCount);
          labels.forEach((day,index)=>{
              const filterData = this.data.find(e=>e.AnalyzerName==item && e.DayName == day);             
          
              if (filterData) {
                datasets[analyzerIndex].data.push(filterData.TotalStudyCount);
              }else {
                datasets[analyzerIndex].data.push(0);           
              }
          })  
         });

    // this.data.forEach((item, index) => {
    //   labels.push(item.AnalyzerName);
    //   const colorIndex = index % slidePlColors.length;
    //   const dfColor = slidePlColors[colorIndex];

    //   datasets[0].backgroundColor.push(dfColor);
    //   datasets[0].borderColor.push(dfColor);
    //   datasets[0].data.push(item.PLAmount);
    // });

    this.barData = {
      labels: labels,
      datasets: datasets,
    };

    this.barOptions = {
      plugins: {
        datalabels: {
          color: "white",
          font: {
            weight: "bold",
          },
          anchor: "end",
          align: "end",
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

}

import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardQeryModel, DashboardViewModel, GeneralResModel } from 'src/app/_models';
import { StudyModel } from 'src/app/_models/study.model';
import { DashboardChartService } from 'src/app/_services/dashboard-chart/dashboard-chart.service';

@Component({
  selector: 'app-analyzer-chart',
  templateUrl: './analyzer-chart.component.html',
  styleUrls: ['./analyzer-chart.component.scss']
})
export class AnalyzerChartComponent implements OnInit ,OnChanges{
  
  chartData :DashboardViewModel = new DashboardViewModel();
  dashboardqueryModel: DashboardQeryModel = new DashboardQeryModel();
  loaderVisible:boolean = false;
  rowData: StudyModel;

  fromDate: Date = new Date();
  toDate: Date = new Date();
  
  minToDate: Date;
  constructor(private _dashboardChartService: DashboardChartService,
    private route: ActivatedRoute,) { 

      this.fromDate.setDate(this.toDate.getDate() - 30);
    
    }

  ngOnInit(): void {
    
   this.getRouteParam();
  }
ngOnChanges(): void {
this.getDashboardData();
}

private getRouteParam() {
  this.route.paramMap.subscribe(params => {
    const encodedRowData = params.get('encodedRowData');
    if (encodedRowData) {
      this.rowData = this.decodeUrlParamToObject(encodedRowData);
      console.log("New Window RowData: ", this.rowData);
      if(this.rowData != null) {
        this.getDashboardData();
      }
    }
  });
}

public getDashboardData() {

  // alert('jj')
  // let endDay = new Date();
  // let startDay = new Date();
  // //this.getSpecies(); 
  // startDay.setDate(startDay.getDate() - 30);
  // this.fromDate = startDay;
  // this.toDate = endDay;
 this.dashboardqueryModel.StartDate = this.fromDate.toDateString();
    this.dashboardqueryModel.EndDate = this.toDate.toDateString();
    this.dashboardqueryModel.OrgIds = [];
    this.dashboardqueryModel.OrgIds.push(this.rowData.OrgId);
this.dashboardqueryModel.OrderId = this.rowData.OrderId;
  this.loaderVisible = true;
  this._dashboardChartService
    .getDashboardAnalyzerData(this.dashboardqueryModel)
    .subscribe((res: GeneralResModel<DashboardViewModel>) => {
      if (res.IsSucceed) {
        this.chartData = res.Result;
      }
      this.loaderVisible = false;
    });
}

onSelectFromDate(e: any) {
  if (this.fromDate < this.toDate) {
    this.minToDate = this.fromDate;
  } else if (this.fromDate >= this.toDate) {
    this.fromDate = new Date(e);
    this.toDate = new Date(e);
    this.minToDate = this.fromDate;
  }
}

private decodeUrlParamToObject(param: string): any {
  return JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(param)))));
}

}

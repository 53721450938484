import { HisRegistrationModel } from "../registration/his-registration.model";
import { HrEmpModel } from "../user-models/hr-emp.model";
import { RisOrderDtlModel } from "./ris-order-dtl.model";

export class RisOrderModel {
  OrderId: number;
  RegId: number;
  Hn: string;
  VisitNo: string;
  AdmissionNo: string;
  OrderDt: string;
  ScheduleId?: number;
  InsuranceTypeId?: number;
  OrderStartTime?: string;
  RefUnit?: number;
  RefDoc?: number;
  PatStatus: string;
  RefDocInstruction: string;
  ClinicalInstruction: string;
  Reason: string;
  Diagnosis: string;
  IcdId?: number;
  ArrivalBy?: number;
  ArrivalOn?: string;
  IsCanceled: string;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: string;
  LastModifiedBy?: number;
  LastModifiedOn?: string;
  LmpDt?: string;
  IsReqonline: string;
  XrayNo: string;
  Requestno: string;
  EncId: string;
  EncType: string;
  IsPrinted: string;
  HosId?: number;
  PatClass: string;
  RefDocNavigation: HrEmpModel;
  Reg: HisRegistrationModel;
  RisOrderdtl: RisOrderDtlModel[];
}

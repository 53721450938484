export class ExamOrderRequestModel {
  RegId: number;
  Hn: string;
  OrgId?: number;
  CreatedBy?: number;
  LastModifiedBy?: number;
  ExamOrderList: ExamOrderDtl[] = [];
}

export class ExamOrderDtl {
  ExamId: number;
  RoomId?: number;
  PanelExamId?: number;
}

import { Component, Input, OnInit } from '@angular/core';
import { DashboardViewModel, HourlyAnalyzerFinalizedDistribution } from 'src/app/_models';

@Component({
  selector: 'app-analyzer-finalized-distribution',
  templateUrl: './analyzer-finalized-distribution.component.html',
  styleUrls: ['./analyzer-finalized-distribution.component.scss']
})
export class AnalyzerFinalizedDistributionComponent implements OnInit {

  @Input() chartData: DashboardViewModel = new DashboardViewModel();
  data: HourlyAnalyzerFinalizedDistribution[] = [];
  lineData: any;
  lineOptions: any;

  colors: string[] = [
    "#1AB587",
    "#93A2E6",
    "#31356E",
    "#90D4D6",
    "#363C57",
    "#516CCD",
    "#959BB8",
    "#A6ED9D",
    "#4D9DE0",
    "#BD4CE0",
    "#A30041",
    "#663400",
    "#A33A83",
    "#86138C",
    "#E04C4C",
    "#8C1313",
    "#F200A9",
    "#CE6A00",
    "#FF0066",
    "#CE9456",
    "#FFD400",
    "#FF2323",
    "#E07474",
    "#FF9E9E",
    "#D8BC2D",
  ];

  constructor() {}

  ngOnChanges(): void {
    this.data = [];
    if (this.chartData.HourlyAnalyzerFinalizedDistribution) {
      this.data.push(
        ...this.chartData.HourlyAnalyzerFinalizedDistribution.map((x) => ({
          AnalyzerName: x.AnalyzerName,
          TotalStudyCount: x.TotalStudyCount,
          HourlyNo: x.HourlyNo,
        }))
      );

      // console.log("this.data: ", this.data);
      if (this.data) {
        this.getDailyFinalizedDistributions();
      }
    }
  }

  ngOnInit(): void {
    this.data = [];
  }

  private getDailyFinalizedDistributions() {
    const labels: string[] = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
    const datasets = [];

    const dailyFinalizedColors: string[] = [
      "#ff8a67",
      "#fab06b",
      "#fc6b6b",
      "#ffcc00",
      "#9933ff",
      "#ff6666",
    ];

       // Create unique list of exam names for labels
       const uniqueAnalyzerName = [...new Set(this.data.map(item => item.AnalyzerName))];
  
      //  labels =hourlyLabels;
       uniqueAnalyzerName.forEach((item, index)=>{
        datasets.push( {
          label: item,
          data: [],
          fill: false,
          backgroundColor: [],
          borderColor: [],
          tension: 0.4,
        },
      );
        const colorIndex = index % dailyFinalizedColors.length;
      const dfColor = dailyFinalizedColors[colorIndex];

      datasets[index].backgroundColor.push(dfColor);
      datasets[index].borderColor.push(dfColor);
      const analyzerIndex = index;
      // datasets[0].data.push(item.TotalStudyCount);
        labels.forEach((hour,index)=>{
            const filterData = this.data.find(e=>e.AnalyzerName==item && e.HourlyNo == Number(hour));
            
        // console.log(hour);
        // console.log(filterData);
            if (filterData) {
              datasets[analyzerIndex].data.push(filterData.TotalStudyCount);
            }else {
              datasets[analyzerIndex].data.push(0);           
            }
        })  
       });

// console.log(datasets);
    // this.data.forEach((item, index) => {
    //   labels.push(item.HourlyNo.toString());
    //   const colorIndex = index % dailyFinalizedColors.length;
    //   const dfColor = dailyFinalizedColors[colorIndex];

    //   datasets[0].backgroundColor.push(dfColor);
    //   datasets[0].borderColor.push(dfColor);
    //   datasets[0].data.push(item.TotalStudyCount);
    // });

    this.lineData = {
      labels: labels,
      datasets: datasets,
    };

    this.lineOptions = {
      plugins: {
          datalabels: {
              color: 'white',
              font: {
                  weight: 'bold'
              },
              anchor: 'start',
              align: 'start'
          }
      },
      scales: {
          y: {
              beginAtZero: true
          }
      }
    }
  }


}

export class InvItemImagesModel {
  ItemImagesId: number;
  Itemid: number;
  ImageURL: string;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: string;
  LastModifiedBy?: number;
  LastModifiedOn?: string;
  IsDefault?: boolean;
}

export class RisExamPanelDtlModel {
  PanelDtlId: number;
  PanelId?: number;
  ExamId?: number;
  PanelexamId?: number;
  Rate: number;
  Tag: string;
  DisplayOrder?: number;
  IsActive?: boolean;
  IsVisible?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  PanelexamName: string;
  RoomName: string;
}

export class PatientInformationModel {
  RegId: number;
  Hn: string;
  Title: string;
  Gender: string;
  GenderText: string;
  Fname: string;
  Mname: string;
  Lname: string;
  TitleEng: string;
  FnameEng: string;
  MnameEng: string;
  LnameEng: string;
  Year?: number;
  Month?: number;
  Day?: number;
  BodyWeight?: number;
  IsNuter?: boolean;
  IsNuterText: string;
  OwnerId?: number;
  TitleNls: string;
  FnameNls: string;
  MnameNls: string;
  LnameNls: string;
  OwnerTitleEng: string;
  OwnerFnameEng: string;
  OwnerMnameEng: string;
  OwnerLnameEng: string;
  OwnerFullName: string;
  OwnerGender: string;
  OwnerGenderText: string;
  EmailPersonal: string;
  PhoneMobile: string;
  Address1: string;
  BreedId?: number;
  BreedText: string;
  BreedIsMixed?: boolean;
  ColorId?: number;
  ColorText: string;
  SpeciesId?: number;
  SpeciesName: string;
  SpeciesText: string;
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {
    imageUrl = environment.imageUrl;
    apiUrl = environment.apiUrl;
    cloudApiUrl = environment.cloudApiUrl;
    //selectedApiUrl = environment.selectedApiUrl;

    constructor(private http: HttpClient) { }

    public get<T>(url: string, params? ): Observable<T> {
        return this.http.get<T>(`${this.apiUrl}${url}`,{params:params});
    }

    public post<T>(url: string, body): Observable<T> {
        return this.http.post<T>(`${this.apiUrl}${url}`, body).pipe(
            catchError(this.handleError)
        );;
    }

    public put<T>(url: string, body): Observable<T> {
        return this.http.put<T>(`${this.apiUrl}${url}`, body).pipe(
            catchError(this.handleError)
        );;
    }

    public delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(`${this.apiUrl}${url}`);
    }

    public patch<T>(url: string, body: string): Observable<T> {
        return this.http.patch<T>(`${this.apiUrl}${url}`, body);
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(() => error.message);
    }
}
import { Component, OnInit } from "@angular/core";
import { User } from "./_models/user-models/user";
import { AppSessionState } from "./_services/common/app.service";
import { AppMainComponent } from "./app.main.component";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[];
  public client: User = new User();

  constructor(
    public appMain: AppMainComponent,
    private _appState: AppSessionState
  ) {}

  ngOnInit() {
    this.client = JSON.parse(this._appState.getSessionStorage());
    this.model = JSON.parse(localStorage.getItem("MENU"));
  }

      // ['SP','Customer','Hospital','Viewer']
    // ['A','U']
    // this.model = [
    //   {
    //     label: "Dashboard",
    //     icon: "pi pi-fw pi-chart-line",
    //     routerLink: ["/dashboard"],
    //     jobType: ["A"],
    //   },
    //   {
    //     label: "Slide Tracker",
    //     icon: "pi pi-eye",
    //     routerLink: ["/study/slide-tracker"],
    //     jobType: ["A"],
    //   },
    //   {
    //     label: "Worklist",
    //     icon: "pi pi-fw pi-book",
    //     routerLink: ["/study/worklist"],
    //     jobType: ["All"],
    //   },
    //   {
    //     label: "Result Entry",
    //     icon: "pi pi-globe",
    //     routerLink: ["/study/exam-result"],
    //     jobType: ["All"],
    //   },
    //   {
    //     label: "Patient Information",
    //     icon: "pi pi-fw pi-book",
    //     jobType: ["All"],
    //     items: [
    //       {
    //         label: "Pet Patient",
    //         icon: "pi pi-users",
    //         routerLink: ["/study/new-registration"],
    //         jobType: ["All"],
    //       },
    //       {
    //         label: "Pet Owner",
    //         icon: "pi pi-users",
    //         routerLink: ["/study/pet-owner"],
    //         jobType: ["All"],
    //       },
    //     ],
    //   },
    //   {
    //     label: "Inventory Management",
    //     icon: "pi pi-database",
    //     jobType: ["A"],
    //     items: [
    //       {
    //         label: "Setup",
    //         routerLink: ["/blocks"],
    //         jobType: ["A"],
    //         items: [
    //           {
    //             label: "Item Master Data",
    //             icon: "pi pi-fw pi-cog",
    //             routerLink: ["/study/item-master"],
    //             jobType: ["A"],
    //           },

    //           {
    //             label: "Item Unit",
    //             icon: "pi pi-fw pi-cog",
    //             routerLink: ["/study/item-unit"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Item Type",
    //             icon: "pi pi-fw pi-cog",
    //             routerLink: ["/study/item-type"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Item Category",
    //             icon: "pi pi-fw pi-cog",
    //             routerLink: ["/study/item-category"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Supplier/Vendor Info.",
    //             icon: "pi pi-users",
    //             routerLink: ["/study/vendor-info"],
    //             jobType: ["A"],
    //           },
    //         ],
    //       },
    //       {
    //         label: "Stock Adjustment",
    //         icon: "pi pi-fw pi-cog",
    //         routerLink: ["/study/add-stock-adjustment"],
    //         jobType: ["A"],
    //       },
    //       {
    //         label: "Purchase Order",
    //         icon: "pi pi-shopping-cart",
    //         routerLink: ["/study/purchase-order"],
    //         jobType: ["A"],
    //       },
    //     ],
    //   },
    //   {
    //     label: "Settings",
    //     icon: "pi pi-fw pi-cog",
    //     jobType: ["A"],
    //     items: [
    //       {
    //         label: "Org Setup",
    //         icon: "pi pi-fw pi-unlock",
    //         routerLink: ["/study/org-info"],
    //         jobType: ["A"],
    //       },
    //       {
    //         label: "User list",
    //         icon: "pi pi-fw pi-users",
    //         routerLink: ["/study/userlist"],
    //         jobType: ["A"],
    //       },
    //       {
    //         label: "Exam Mapping",
    //         jobType: ["A"],
    //         items: [
    //           {
    //             label: "Exam Info.",
    //             icon: "pi pi-align-justify",
    //             routerLink: ["/study/exam-info"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Exam Panel",
    //             icon: "pi pi-align-justify",
    //             routerLink: ["exam-panel"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Exam Reference Value",
    //             icon: "pi pi-palette",
    //             routerLink: ["exam-referencevalue"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Department",
    //             icon: "pi pi-book",
    //             routerLink: ["/study/department-list"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Sample",
    //             icon: "pi pi-bolt",
    //             routerLink: ["/study/exam-sample"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Exam Consumable",
    //             icon: "pi pi-list",
    //             routerLink: ["/study/exam-consumable"],
    //             jobType: ["A"],
    //           },
    //         ],
    //       },
    //       {
    //         label: "Analyzer",
    //         routerLink: ["/blocks"],
    //         jobType: ["A"],
    //         items: [
    //           {
    //             label: "Analyzer Info.",
    //             icon: "pi pi-list",
    //             routerLink: ["/study/analyzer-info"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Dietary Types",
    //             icon: "pi pi-list",
    //             routerLink: ["/study/dietarytypes"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Analyzer Dietary Types",
    //             icon: "pi pi-list",
    //             routerLink: ["/study/analyzer-dietarytypes"],
    //             jobType: ["A"],
    //           },
    //         ],
    //       },
    //       {
    //         label: "Job Type Setup",
    //         routerLink: ["/blocks"],
    //         jobType: ["A"],
    //         items: [
    //           {
    //             label: "Job Type",
    //             icon: "pi pi-fw pi-lock",
    //             routerLink: ["/study/jobtype-list"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Job Type Level",
    //             icon: "pi pi-fw pi-lock",
    //             routerLink: ["/study/jobtypelevel-list"],
    //             jobType: ["A"],
    //           },
    //           {
    //             label: "Job Type Permission",
    //             icon: "pi pi-fw pi-lock",
    //             routerLink: ["/study/jobtypepermission-list"],
    //             jobType: ["A"],
    //           },
    //         ],
    //       },
    //       {
    //         label: "Menu",
    //         jobType: ["A"],
    //         items: [
    //           {
    //             label: "Menu List",
    //             icon: "pi pi-bars",
    //             routerLink: ["/study/menu-list"],
    //             jobType: ["SP"],
    //           },
    //           {
    //             label: "Menu Job Type",
    //             icon: "pi pi-cog",
    //             routerLink: ["/study/menu-jobtype-permission"],
    //             jobType: ["SP"],
    //           }
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     label: "Reports",
    //     icon: "pi pi-file-pdf",
    //     jobType: ["All"],
    //     items: [
    //       {
    //         label: "Report Job Type Setting",
    //         icon: "pi pi-fw pi-cog",
    //         routerLink: ["/study/report-jobtypesetting"],
    //         jobType: ["A"],
    //       },
    //       {
    //         label: "Report Viewer",
    //         icon: "pi pi-eye",
    //         routerLink: ["/study/report-viewer"],
    //         jobType: ["All"],
    //       },
    //     ],
    //   },
    //   {
    //     label: "Report Template",
    //     icon: "pi pi-id-card",
    //     routerLink: ["/study/report-template"],
    //     jobType: ["A"],
    //   },
    // ];

  menuHide(menu: any) {
    let res = true; 
    menu.jobType.forEach((element) => {
      if (element == this.client.JobType || element == this.client.OrgType) {
        res = false;
      }

      if (element == "All") {
        res = false;
      }

      if (element == "SP") {
        res = false;
      }

    });
    return res;
  }

  onMenuClick() {
    this.appMain.menuClick = true;
  }
}

export class GblEnvViewModel {
  OrgId: number;
  CountryId?: number;
  ProvinceId?: number;
  DistrictId?: number;
  SubdistrictId?: number;
  ZipCode?: number;
  OrgUid: string;
  OrgName: string;
  ParentOrgName: string;
  OrgAlias: string;
  OrgSlogan1: string;
  OrgSlogan2: string;
  OrgAddr1: string;
  OrgAddr2: string;
  OrgAddr3: string;
  OrgAddr4: string;
  OrgTel1: string;
  OrgTel2: string;
  OrgTel3: string;
  OrgFax: string;
  OrgEmail1: string;
  OrgEmail2: string;
  OrgEmail3: string;
  OrgWebsite: string;
  OrgImg: string;
  WelcomeDialog1: string;
  WelcomeDialog2: string;
  DefaultFontFace: string;
  DefaultFontSize?: number;
  RepServer: string;
  RepFormat: string;
  RepFooter1: string;
  RepFooter2: string;
  EmpImgType: string;
  EmpImgMaxSize: string;
  OtherMaxFileSize?: number;
  DtFmt: string;
  TimeFmt: string;
  LocalCurrencyName: string;
  LocalCurrencySymbol: string;
  CurrencyFmt: string;
  ResourceImagePath: string;
  ScannedImagePath: string;
  DocumentPath: string;
  BackupPath: string;
  OtherFilePath: string;
  EmpImgPath: string;
  LabDataPath: string;
  UserDisplayFmt: string;
  VendorH1: string;
  VendorH2: string;
  VendorLogoPath: string;
  Partner1H1: string;
  Partner1H2: string;
  Partner1LogoPath: string;
  Partner2H1: string;
  Partner2H2: string;
  Partner2LogoPath: string;
  RisIp: string;
  RisPort: string;
  RisUser: string;
  RisPass: string;
  RisUrl: string;
  PacsIp: string;
  PacsPort: string;
  PacsUrl1: string;
  PacsUrl2: string;
  PacsUrl3: string;
  PacsDomain: string;
  PacsAeTitle: string;
  HisIp: string;
  HisPort: string;
  HisDbName: string;
  HisUserName: string;
  HisUserPass: string;
  HisFinFlag: string;
  WsIp: string;
  WsIpPicture: string;
  WsVersion: string;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  OrmSyncIp: string;
  OrmSyncPort: string;
  OruSyncIp: string;
  OruSyncPort: string;
  HisSyncIp: string;
  HisSyncPort: string;
  OlapUrl: string;
  IsCloud: boolean;
  IsApproved?: boolean;
  CloudOrgId?: number;
  OrgPrefix: string;
  OrgSuffix: string;
  ApprovedOn?: Date;
  PacsEndpoint: string;
  BatchMwl?: boolean;
  WarningQueue?: number;
  BufferQueue?: number;
  BusQueue?: number;
  HighPriority?: number;
  AutoSendToPacs?: boolean;
  RisApiUrl: string;
  DCMViewer: string;
  RemoteHL7IP: string;
  RemoteHL7Port: string;
  OrgType: string;
  OrgParentId?: number;
  LineId: string;
  AddendumPosition: string;
  OrgImgString: string;
  ShowPatientNameToRad: boolean;
  SendPatientNameToPacs: boolean;
  DefaultPatientType: string;
  Hcode: string;
  Hospmain: string;
  HospSub: string;
  GovCode: string;
  GovName: string;
  PermitNo: string;
  HType: string;
  HTypeId?: number;
  BaseURL: string;
  UserName: string;
  Password: string;
  AuthAPI: string;
  AuthType: string;
  SubmitAPI: string;
  SubmitAPIType: string;
  ReceiveBaseURL: string;
  ReceiveUserName: string;
  ReceivePassword: string;
  ReceiveAPI: string;
  ReceiveAPIType: string;
  DeviceName: string;
  DeviceAeTitle: string;
  DeviceIp: string;
  DevicePort?: number;
  SenderAiTitle: string;
  DisplayStudyVersion?: boolean;
  StudyVersionCustomText: string;
  RemoteHL7Version: string;
  AutoRefreshAllow?: boolean;
  AutoRefreshSecond?: number;
  ApiSubmitCriteriaId?: number;
  ApiSubmitIntervalHour?: number;
  ApiSubmitScheduleOn?: Date;
  RadologistWorkloadDuration?: number;
  RadologistFinalizedDuration?: number;
  SessionActiveSecond?: number;
  ReSubmitAllow?: boolean;
  RisApiMethodType: string;
  ApiSubmitScheduleDay?: number;
  LocalPacsHost: string;
  LocalPacsPort?: number;
  LocalPacsUsetls?: boolean;
  LocalPacsCallingae: string;
  LocalPacsCalledae: string;
  RisApiMethod: string;
  PackageName: string;
  PackageId: number;
  Rate?: number;
  InactivatedReason: string;
  NextBillingDate?: Date;
  ActiveDate?: Date;
  OrgNameMapTo: string;
  IsMappingActive?: boolean;
  EnvMappingId: number;
  MappingOrgName: string;
  IsImaging?: boolean;
  IsReferring?: boolean;
  OrgIdMapTo?:number;
  InactivatedDate?:Date;
}

export class SlideTrackerData {
  ItemBarData: STChartData;
  ItemPieData: STChartData;
  ItemLineData: STChartData;
}

export class STChartData {
  labels: string[];
  datasets: ChartDataset[];
}

export class ChartDataset {
  label: string;
  backgroundColor: string;
  borderColor: string;
  tension: number;
  hoverBackgroundColor: string;
  data: number[];
}

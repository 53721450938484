export class ResetStudyReqModel {
  OrderId: number;
  AccessionNo: string;
  ResetBy: number;
  ResetByName: string;
  ResetReason: string;
  ResetTo: string;
  ResetFrom: string;
  ResetreasonId?: number;
  IsLoadReport: boolean = true;
  IsLoadLastVersionReport: boolean = true;
}

import { InvUnitModel } from "../lab-setup/inv-unit.model";

export class InvPOViewModel {
  PoId: number;
  PrId?: number;
  PoUid: string;
  Toc: string;
  Remarks: string;
  ExpectedDeliveryDate?: Date;
  DiscountAmount?: number;
  VatTaxAmount?: number;
  TotalAmount?: number;
  PoStatus: string;
  GeneratedOn?: Date;
  VendorId?: number;
  VendorName: string;
  PoDTLId: number;
  ItemId?: number;
  ItemName: string;
  UnitId?: number;
  UnitName: string;
  ReqQty?: number;
  Qty?: number;
  ReceiveQty?: number;
  UnitPrice?: number;
  Amount?: number;
  DTLDiscountAmount?: number;
  PoItemStatus: string;
  IsActive?: boolean;
  OrgId?: number;
  OrgName: string;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  IsApproved?: boolean;
  ApprovedBy?: number;
  ApprovedOn?: Date;
  ApprovedRemarks: string;
  ApprovedName: string;
  IsCancel?: boolean;
  CancelBy?: number;
  CancelOn?: Date;
  CancelRemarks: string;
  CancelName: string;
  ReceiptUid: string;
  ReceiptId?: number;

  BaseUnitId?: number;
  BaseUnitName: string;

  PurchaseUnitName: string;
  PurchaseunitId?: number;
  PurchasePrice?: number;
  PurchaseconPrice?: number;
  PurchaseconValue?: number;
  PurchaseUnitList: InvUnitModel[] = [];
  InvQty?: number;
  AdjustedQty?: number;
  InvUnit: string;
  InvUnitId?: number;
  ItemUnitName: string;
  ItemUnitId?: number;
  PoUnitId: number;
  RequnitId?: number;
  RequnitName: string;
}

import { AnalyzerDietarytypeModel } from "./analyzer-dietarytype.model";

export class AnalyzerModel {
  AnalyzerId: number;
  AnalyzerName: string;
  Description: string;
  PurchaseDate?: Date;
  PurchasePrice?: number;
  IsRental?: boolean;
  RentalTerminday?: number;
  RentalAmount?: number;
  SerialNo?: number;
  IsActive?: boolean;
  IsDefault?: boolean;
  IsIgnored?: boolean;
  IsIncludeSlide?: boolean;
  DepreciationMonth?: number;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;

  DietarytypeId?: number;
  DietaryType: string;
  Dietarytype: string;
  AnalyzerDietarytypeList?: AnalyzerDietarytypeModel[];
}

export class DashboardQeryModel {
  StartDate: string;
  EndDate: string;
  SpeciesId?: number;
  OrgId?: number;
  AnalyzerId?: number;
  OrderId?: number;
  OrgIds?: number[];
  ComparisonOrgIds?: number[];
  AnalyzerName?: string[];
}

export class PmsPetColorModel {
  ColorId: number;
  ColorText: string;
  ColorDesc: string;
  IsActive: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
}

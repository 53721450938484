import { SafeResourceUrl } from "@angular/platform-browser";

export class RisExamResultKeyImagesModel {
  ExamresultkeyimagesId: number;
  OrderId?: number;
  SlNo: number;
  KeyImage: string;
  OrgId?: number;
  CreatedBy: number;
  CreatedOn?: Date;
  ImageURL: string;
  ImageURLSafe: SafeResourceUrl;
  LastModifiedOn?: Date;
  LastModifiedBy?: number;
}

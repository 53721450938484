import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_services/auth/auth.service";

import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store } from "@ngrx/store";
import { environment } from "../../environments/environment";
import { GeneralResModel, MenuResModel } from "../_models";
import { User } from "../_models/user-models/user";
import { AppMenuService, DataService } from "../_services";
import { AlertifyService } from "../_services/common/alertify.service";
import { AppSessionState } from "../_services/common/app.service";
import { login } from "../_store/actions/login.actions";
import { UserSessionStateModel } from "../_store/models/user-session-state.model";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  client: User;
  dark: boolean;
  checked: boolean;
  loginModel: any = {};
  showSpinner: boolean;
  isCloud: boolean = true;
  isDisabled: boolean = false;
  loaderVisible: boolean = false;
  menuData: MenuResModel[] = [];

  constructor(
    private router: Router,
    private store: Store<any>,
    private authService: AuthService,
    private alertify: AlertifyService,
    private _dataService: DataService,
    private _appMenuService: AppMenuService,
    private appSessionState: AppSessionState,
    private jwtHelperService: JwtHelperService
  ) {}

  ngOnInit() {
    localStorage.clear();

    this.isCloud = environment.isCloud;
    environment.isSiteAdmin = false;

    if (this.jwtHelperService.isTokenExpired(localStorage.getItem("token"))) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    } else {
      this.routeToStudyWorklist();
    }
  }

  private setMenuItemsNullIfEmpty(item: any): any {
    if (item.items && item.items.length === 0) {
      item.items = null; 
    } else if (item.items && item.items.length > 0) {
      item.items = item.items.map(subItem => {
        return this.setMenuItemsNullIfEmpty(subItem);
      });
    }
    return item;
  }

  private getMenuList(empId: number) {
    this._appMenuService.getMenuList(empId).subscribe((res: GeneralResModel<MenuResModel[]>) => {
      if(res.IsSucceed) {
        this.menuData = res.Result;
    
        this.menuData = this.menuData.map(item => {
          return this.setMenuItemsNullIfEmpty(item);
        });

        localStorage.setItem("MENU", JSON.stringify(this.menuData));
        
        if(this.menuData.length > 0) {
          if (this.client.Is2faenable) {
            this.routeToTwoFAVerify();
          } else {
            this.routeToLandingPage(this.client.LandingPageUrl);
          }
        } else {
          this.alertify.error("You do not have permission to access any menus. Please contact the administrator.");
        }
      }
    });
  }

  login() {
    this.loaderVisible = true;
    this.loginModel.IsCloud = this.isCloud;
    this._dataService.sharedLoginReqData = this.loginModel;
    this.authService.login(this.loginModel).subscribe(
      (data) => {
        if (data) {
          if (data.user.IsSuperAdmin) {
            environment.isSuperAdmin = true;
          }

          if (data.user.JobType == "A") {
            environment.isSiteAdmin = true;
          }

          this.appSessionState.setToken(
            data.tokenString,
            <User>data.userRights,
            "" + new Date()
          );

          this.appSessionState.setUserToSessionStorage(data.user);
          localStorage.setItem("immediateReport", "true");
          this.setUserSessionState(data);
          this.loaderVisible = false;

          this.client = JSON.parse(localStorage.getItem("user"));
          this.getMenuList(this.client.EmpId);
        }
      },
      (error) => {
        this.loaderVisible = false;
        this.alertify.error("Failed to login");
      }
    );
  }

  private routeToLandingPage(url: string | null | undefined) {
    // If the URL is empty or null, set a default URL
    const defaultRoute = '/study/worklist';
    
    // Trim any spaces and convert the URL to lowercase
    const formattedUrl = url?.trim().toLowerCase() || '';
    
    // Use the provided formatted URL if it's not empty, otherwise, use the default route
    const route = formattedUrl ? `/${formattedUrl}` : defaultRoute;
  
    // Navigate to the route
    this.router.navigate([route]);
  
    // Show a success message
    this.alertify.success("Logged in successfully.");
  }
  
  

  // private routeToLandingPage(url: string) {
  //   const routeMap = new Map<string, string>([
  //     ['dashboard', '/dashboard'],
  //     ['worklist', '/study/worklist'],
  //     ['slide-tracker', '/slide-tracker'],
  //   ]);
  
  //   const route = routeMap.get(url) || '/study/worklist';

  //   this.router.navigate([route]);

  //   this.alertify.success("Logged in successfully.");
  // }
  

  setUserSessionState(data) {
    let loginModel: UserSessionStateModel = {
      LoginTime: new Date(),
      UserInfo: data.user,
    };
    this.store.dispatch(login({ userSessionDetail: loginModel }));
  }

  routeToStudyWorklist() {
    this.router.navigate(["/study/worklist"]);
  }

  private routeToTwoFAVerify() {
    this.router.navigate(["/two-factor-auth-verify"]);
  }

  showLoadingSpinner() {
    this.showSpinner = true;
    this.isDisabled = true;
  }

  hideLoadingSpinner() {
    this.showSpinner = false;
    this.isDisabled = false;
  }

  goToLink() {
    window.open("https://" + environment.OAuthUrl, "_self");
  }

  gotoForgetPassword() {
    this.router.navigate(["/reset-password/radiologist"]);
  }
}
